import { Component } from '@angular/core';
import { ShareService } from './share/share.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  splash: boolean = true;
  routeLink = ''
  constructor(private share_: ShareService, private location: Location) {
    // localStorage.setItem('language' , 'mm')
    if (localStorage.getItem('language')) {
      if (localStorage.getItem('language') == 'mm') {
        this.share_.switchLanguage('mm')
      } else {
        this.share_.switchLanguage('en')
      }
    } else {
      this.share_.switchLanguage('mm')
    }

    if (window.location.href.includes('success')) {
      this.splash = false
    } else {
      setTimeout(() => {
        this.splash = false;
      }, 2000);
    }

  }



  ngAfterContentChecked() {

    let toast = document.querySelector("ion-toast")
    let alert_button = document.querySelector("ion-alert .alert-button-inner")
    let alert_title = document.querySelector("ion-alert .alert-title")


    this.routeLink = this.location.path();
    if (localStorage.getItem('language')) {
      if (localStorage.getItem('language') == 'mm') {
        if (toast) {
          toast.classList.add('mmfont')
        }
        if (alert_button) {
          alert_button.classList.add('mmfont')
        }
        if (alert_title) {
          alert_title.classList.add('mmfont')
        }
      } else {
        if (toast) {
          toast.classList.add('enfont')
        }
        if (alert_button) {
          alert_button.classList.add('enfont')
        }
        if (alert_title) {
          alert_title.classList.add('enfont')
        }
      }
    }

  }

}

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Shop } from '../DTO/shop.dto';

@Injectable({
  providedIn: 'root'
})



export class ShareService {


  private REST_API_SERVER = environment.apiServer;
  isLoading = false;
  font: string = ''

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public menuController: MenuController,
    public modalController: ModalController,
    public translate: TranslateService,
    private httpClient: HttpClient,
    public alertController: AlertController,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public activeRoute: ActivatedRoute,

  ) {

  }


  async loadingDismiss() {
    await this.loadingController.dismiss()
  }

  // gradients = [

  //   {
  //     "background": ["#0984e3", "#55efc4"]
  //   },
  //   {
  //     "background": ["#ff7675", "#ffeaa7"]
  //   },
  //   {
  //     "background": ['#778beb', '#f5aeae']
  //   }, {
  //     "background": ['#e977eb', '#fb8585']
  //   },
  //   {
  //     "background": ['#f7d794', '#f5cd79']
  //   }
  // ]

  gradients = [
    {
      "background": ["#1fe4f5", "#3fbafe"]
    },
    {
      "background": ["#fbc1cc", "#fa99b2"]
    },
    {
      "background": ['#76b2fe', '#b69efe']
    }, {
      "background": ['#60efbc', '#58d5c9']
    },
    {
      "background": ['#f588d8', '#c0a3e5']
    },
    {
      "background": ['#caff8c', '#ff6ea7']
    }
  ]

  public getRequestWithParams(url: string, params: HttpParams) {
    return this.httpClient.get(this.REST_API_SERVER + url, { params: params });
  }

  public getRequest(url: string) {
    return this.httpClient.get(this.REST_API_SERVER + url);
  }

  public postRequest(url: string, data) {
    return this.httpClient.post(this.REST_API_SERVER + url, data)
  }

  public postRequestParam(url: string) {
    return this.httpClient.post(this.REST_API_SERVER + url, null)
  }

  public putRequest(url: string, data) {
    return this.httpClient.put(this.REST_API_SERVER + url, data)
  }

  public putRequestParam(url: string) {
    return this.httpClient.put(this.REST_API_SERVER + url, null)
  }

  public deleteRequest(url: string, data) {
    return this.httpClient.delete(this.REST_API_SERVER + url, data)
  }

  public deleteRequestParam(url: string,) {
    return this.httpClient.delete(this.REST_API_SERVER + url)
  }

  public clearData() {
    // localStorage.removeItem("survey_type");
    // localStorage.removeItem("survey");
    // localStorage.removeItem("reg_current_survey");
    // localStorage.removeItem("format_survey");
    // localStorage.removeItem("regular_survey");
    // localStorage.removeItem("user_type");
    // localStorage.removeItem("current_survey");
    // localStorage.removeItem("intro-survey");
    // localStorage.removeItem("result");
    // localStorage.removeItem("service_type");
    // localStorage.removeItem("regular_selected_survey");
    // localStorage.removeItem("result-data");
    // localStorage.removeItem("userData");
    // localStorage.removeItem("save_survey");
  }

  convertData(data: any) {
    return JSON.parse(JSON.parse(data).ResponseData)
  }

  convertInfo(data: any) {
    return JSON.parse(JSON.parse(data).ResponseInfo)
  }

  goHome() {
    let shop_id: Shop = JSON.parse(localStorage.getItem('shop_data')).ShopID
    this.router.navigate(['home'], { queryParams: { shop_id: shop_id } })
  }


  public switchLanguage(language: string) {
    this.translate.use(language);
    this.font = language + 'font'
    localStorage.setItem("language", language);
    if (language == 'mm') {
      document.body.classList.add('mmfont')
      if (document.getElementById('alert-4-hdr')) {
        let alert = document.getElementById('alert-4-hdr') as HTMLElement

        alert.style.fontFamily = 'NotoSansMyanmar'
      }

      if (document.getElementById('alert-2-hdr')) {
        let alert = document.getElementById('alert-2-hdr') as HTMLElement

        alert.style.fontFamily = 'NotoSansMyanmar'
      }
    }
  }


  async presentLoading() {
    const loading = await this.loadingController.create({
      mode: 'md',
      cssClass: 'my-custom-class',
      message: '',
      duration: 1000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }


  async presentToast(message, duration, color) {
    const toast = await this.toastController.create({
      mode: 'md',
      message: message,
      duration: duration,
      color: color
    });
    toast.present();
  }

  async presentAlert(header, subtitle, message, button) {
    const alert = await this.alertController.create({
      // cssClass: 'my-custom-class',
      mode: 'md',
      header: header,
      subHeader: subtitle,
      message: message,
      buttons: [button]
    });

    await alert.present();
  }




}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { EMPTY } from 'rxjs';
// import { ShareService } from './share.service';
import { AlertController } from '@ionic/angular';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {


  /*
 -----------------------------------------------------
 ---------------  Http Handler  ----------------------
 -----------------------------------------------------
*/

  constructor(private alertController: AlertController,) { }


  async presentAlert(header, subtitle, message, button) {
    const alert = await this.alertController.create({
      mode: 'md',
      cssClass: 'my-custom-class',
      header: header,
      subHeader: subtitle,
      message: message,
      buttons: [button]
    });

    await alert.present();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



    req = req.clone({ headers: req.headers.set('Content-Type', "application/json") });
    req = req.clone({ responseType: 'json' });
    //   req = req.clone({ headers: req.headers.set("Accept-Charset", "charset=utf-8") })
    //  req = req.clone({ headers: req.headers.set("Access-Control-Allow-Origin", "*") })
    //   req = req.clone({ headers: req.headers.set("X-Tenant-ID", "tenant_default") })
    //   req = req.clone({ headers: req.headers.set("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS") })
    //   req = req.clone({ headers: req.headers.set("Access-Control-Allow-Headers", "Origin, Content-Type, X-Tenant-ID, Content-Type") })

    req = req.clone({ headers: req.headers })
    req = req.clone({ body: req.body });

    console.log("Request Body", JSON.stringify(req.body))
    console.log("Request Params", req.urlWithParams)
    if (!req.urlWithParams)
      return EMPTY
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        console.log("API Data", event);

        return event
      }),
      catchError((error: HttpErrorResponse) => {
        // console.log("HTTP ERROR", error);

        if (localStorage.getItem('language')) {
          if (localStorage.getItem('language') == 'mm') {
            this.presentAlert('အသိပေးချက်', '', 'တစ်စုံတစ်ခုမှားယွင်းနေပါသည်', 'အိုကေ')
          } else {
            this.presentAlert('Something went wrong', '', 'Please check your internet connection or try again!', 'OK')
          }
        } else {
          this.presentAlert('Something went wrong', '', 'Please check your internet connection or try again!', 'OK')
        }


        if (error.error) {
          if (typeof error.error == 'string') {
            // this.share_.presentToast('Something went wrong!', 2000, 'danger')
          } else {
            // this.toastrService.error(error.error.message || "Internal Server error!");
            // this.share_.presentToast('Something went wrong!', 2000, 'danger')
          }
        }
        else {
          // this.share_.presentToast('Something went wrong!', 2000, 'danger')

        }
        return throwError(error)
      })
    );
  }

}
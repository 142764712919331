import { NgModule } from '@angular/core';
import { ProductCardComponent } from '../elements/product-card/product-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from './safe.pipe';
import { CommonModule } from '@angular/common';
import { ShopCardComponent } from '../elements/shop-card/shop-card.component';
import { ShopCardNewComponent } from '../elements/shop-card-new/shop-card-new.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ProductCardNewComponent } from '../elements/product-card-new/product-card-new.component';
import { IonicModule } from '@ionic/angular';
import { SelectProductCardComponent } from '../elements/select-product-card/select-product-card.component';


@NgModule({
  declarations: [
    ProductCardComponent,
    SafePipe,
    ShopCardComponent,
    ShopCardNewComponent,
    ProductCardNewComponent,
    SelectProductCardComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    LazyLoadImageModule,
    IonicModule
  ],
  exports: [
    ProductCardComponent,
    TranslateModule,
    CommonModule,
    ShopCardComponent,
    ShopCardNewComponent,
    ProductCardNewComponent,
    SafePipe,
    LazyLoadImageModule,
    IonicModule,
    SelectProductCardComponent
  ]
})
export class ShareModule { }

import { Component, Input, OnInit } from '@angular/core';
import { ShareService } from 'src/app/share/share.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shop-card-new',
  templateUrl: './shop-card-new.component.html',
  styleUrls: ['./shop-card-new.component.scss'],
})
export class ShopCardNewComponent implements OnInit {


  defaultLoadingImage: string = environment.defaultLoadingImage
  errorImage: string = environment.errorImage

  @Input() partnerData: any = []
  font
  langulage

  constructor(private share_: ShareService) { }

  ngAfterContentChecked() {
    this.font = this.share_.font
    this.langulage = localStorage.getItem('language') || 'en'
  }

  ngOnInit() {
    console.log(this.partnerData, 'partnerdata..')
  }

  partnerClick(shopId: string, CategoryID: string) {
    this.share_.router.navigate(['partner-item-list'], { queryParams: { partner_id: shopId, category_id : CategoryID } })
  }

  getDes(text: string) {
    return text.split('~')[0]
  }

  getDis(text: string) {
    return text.split('~')[1]
  }

}

import { Injectable } from '@angular/core';
import { ShareService } from '../share/share.service';

@Injectable({
  providedIn: 'root'
})
export class ShoplistService {

  constructor(
    private share_: ShareService
  ) { }

  getAllShop(param: any) {
    return this.share_.postRequest('getAllShop', param);
  }

  getShopWithCategory() {
    return this.share_.postRequest('GetPartnerListWithCategory', null);
  }
}

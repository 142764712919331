import { Component, Input, OnInit } from '@angular/core';
import { Shop } from 'src/app/DTO/shop.dto';
import { ShoplistService } from 'src/app/services/shoplist.service';
import { ShareService } from 'src/app/share/share.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.scss'],
})
export class ShopCardComponent implements OnInit {

 shops : Shop[] = [];
@Input() boxshadow : boolean
@Input() shop : Shop
linkAddress : string  = ''

  constructor(
    private share_ : ShareService,
    private getshop_ : ShoplistService
  ) { 
    this.linkAddress  =  environment.linkAddress
  }

  ngOnInit() {}

  itemClick(shop : Shop){
    this.share_.router.navigate(['partner'] , {queryParams : { partner_id : shop.ShopID }})
    localStorage.setItem('shop_data' , JSON.stringify(shop))
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'partner',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/shop-list/shop-list.module').then(m => m.ShopListPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'select-products',
    loadChildren: () => import('./pages/select-products/select-products.module').then(m => m.SelectProductsPageModule)
  },
  {
    path: 'verification',
    loadChildren: () => import('./pages/verification/verification.module').then(m => m.VerificationPageModule)
  },
  {
    path: 'qr-info',
    loadChildren: () => import('./pages/qr-info/qr-info.module').then(m => m.QrInfoPageModule)
  },
  {
    path: 'complete',
    loadChildren: () => import('./pages/complete/complete.module').then(m => m.CompletePageModule)
  },
  {
    path: 'termsandconditions',
    loadChildren: () => import('./pages/termsandconditions/termsandconditions.module').then(m => m.TermsandconditionsPageModule)
  },
  {
    path: 'success',
    loadChildren: () => import('./pages/success/success.module').then(m => m.SuccessPageModule)
  },
  {
    path: 'partner-item-list',
    loadChildren: () => import('./pages/partner-item-list/partner-item-list.module').then(m => m.PartnerItemListPageModule)
  },
  {
    path: 'verification-thingyan',
    loadChildren: () => import('./pages/verification-thingyan/verification-thingyan.module').then(m => m.VerificationThingyanPageModule)
    
  },  {
    path: 'vip-promotion',
    loadChildren: () => import('./pages/vip-promotion/vip-promotion.module').then( m => m.VipPromotionPageModule)
  },
  {
    path: 'normal-promotion',
    loadChildren: () => import('./pages/normal-promotion/normal-promotion.module').then( m => m.NormalPromotionPageModule)
  },






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

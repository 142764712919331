import { Component, Input, OnInit } from '@angular/core';
import { ShareService } from 'src/app/share/share.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-card-new',
  templateUrl: './product-card-new.component.html',
  styleUrls: ['./product-card-new.component.scss'],
})
export class ProductCardNewComponent implements OnInit {


  defaultLoadingImage: string = environment.defaultLoadingImage
  errorImage: string = environment.errorImage

  @Input() itemData: any = []

  constructor(
    private share_: ShareService
  ) { }

  ngOnInit() { }


  itemClick(ProductID: string) {
    this.itemData.check = !this.itemData.check

    let customerData = JSON.parse(localStorage.getItem('customer_data'))
    let productData = JSON.parse(localStorage.getItem('customer_data')).ProductList
    let index = productData.findIndex((x: any) => x.ProductID == ProductID)
    if (index != -1) {
      productData[index].check = this.itemData.check
    }

    customerData.ProductList = productData

    localStorage.setItem('customer_data', JSON.stringify(customerData))


  }


}

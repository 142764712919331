import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Shop } from "../DTO/shop.dto";
import { ShareService } from "../share/share.service";


@Injectable({
  providedIn: "root"
})
export class ShopService {


  constructor(
      private share_ : ShareService,
      private http : HttpClient
      ) {

  }

  getShopData(param : any) {
     return this.share_.postRequest('getShopByShopID' , param);
  }

  getShopBannerShopID(param){
    return this.share_.postRequest('getShopBannerShopID' , param);
  }

}
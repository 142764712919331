import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/DTO/product.dto';
import { Shop } from 'src/app/DTO/shop.dto';
import { ShopService } from 'src/app/services/shop-service.service';
import { ShareService } from 'src/app/share/share.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select-product-card',
  templateUrl: './select-product-card.component.html',
  styleUrls: ['./select-product-card.component.scss'],
})
export class SelectProductCardComponent implements OnInit {

  isProductSelected: boolean = false;
  products: Product[] = [];

  @Input() product: any = [];


  shop !: Shop
  

  selected: boolean = false;
  routeLink
  gradient
  language
  linkAddress: string = ''

  param: any
  @Output() productSelected = new EventEmitter<string>();


  constructor(private share_: ShareService, private shopService_: ShopService, private location: Location, private activeRoute : ActivatedRoute) {
    this.linkAddress = environment.linkAddress
  }

  ngOnInit() {
    console.log(this.product, 'product')
    // this.getShop();
    this.gradient = this.share_.gradients
    this.clearData()
    this.getSelected()
    // alert(this.gradient[this.getRandomInt(20)].colors)

    this.onProductSelect()
   
  }

  onProductSelect(): void {
    this.productSelected.emit(this.product.id); // Emit the product ID when it's selected


  }


  getSelected() {

    let localProduct = JSON.parse(localStorage.getItem('products')) || null
    if (localProduct != null) {
      let index = localProduct.findIndex((x: any) => x.ProductID === this.product.ProductID)
      if (index != -1) {
        this.selected = true
      } else {
        this.selected = false
      }
    }
    this.isProductSelected = true;
  }

  clearData() {
    // localStorage.removeItem('products')
    // localStorage.removeItem('click_count')
    // localStorage.removeItem('real_click_count')
  }


  ConvertDecimal(number: string) {
    // if (number.split(".")[1] == '00') {
    //   let number_ = number.split(".")[0]

    //   return number_
    // } else {
    //   return number
    // }
    return number
  }




  ngAfterContentChecked() {
    this.routeLink = this.location.path();
    this.language = localStorage.getItem('language')
  }


  checkItemClick(selectedProduct: Product) {
    if (localStorage.getItem('click_count')) {
      if (this.selected == false) {
        let click_count = + localStorage.getItem('click_count') + 1
        localStorage.setItem('click_count', String(click_count))
        localStorage.setItem('real_click_count', String(click_count))
      } else {
        let click_count = + localStorage.getItem('click_count') - 1
        localStorage.setItem('click_count', String(click_count))
        localStorage.setItem('real_click_count', String(click_count))
      }

      if (localStorage.getItem('limit')) {
        if (Number(localStorage.getItem('click_count')) > Number(localStorage.getItem('limit'))) {

          if (this.selected == true) {

          } else {
            localStorage.setItem('click_count', localStorage.getItem('limit'))
            if (this.language == 'en') {
              this.share_.presentAlert('Warning !', '', 'Over Discount Item Limit.', 'OK')
            } else {
              this.share_.presentAlert('အသိပေးချက် !', '', '<span class="enfont">Discount </span> ရယူနိုင်သော အရေအတွက် ကျော်လွန်နေပါသည်။', 'အိုကေ')
            }
          }
          this.selected = false

        } else {
          this.selected = !this.selected;
        }
      }

    } else {
      localStorage.setItem('click_count', '1')
      this.selected = true
    }

    // save to local storage
    if (localStorage.getItem('limit')) {

      if (Number(localStorage.getItem('limit')) >= Number(localStorage.getItem('real_click_count'))) {



        if (localStorage.getItem('products')) {
          alert('1')
          let local_product = JSON.parse(localStorage.getItem('products'))
          let index = local_product.findIndex(x => x.ProductID == selectedProduct.ProductID)
          if (index != -1) {
            local_product.splice(index, 1)
            localStorage.setItem('products', JSON.stringify(local_product))
            this.products = []
            return
          }
        }



        if (localStorage.getItem('products')) {
          alert('2')
          let local_product = JSON.parse(localStorage.getItem('products'))

          for (let i = 0; i < local_product.length; i++) {
            this.products.push(
              {
                'DiscountAmount': local_product[i].DiscountAmount,
                'DiscountPercent': local_product[i].DiscountPercent,
                'OriginalDiscountPercent':local_product[i].OriginalDiscountPercent,
                'OriginalDiscountAmount':local_product[i].OriginalDiscountAmount,
                'ImageURL': local_product[i].ImageURL,
                'ProductCode': local_product[i].ProductCode,
                'ProductID': local_product[i].ProductID,
                'ProductName': local_product[i].ProductName,
                'ShopID': local_product[i].ShopID,
                'Unit': local_product[i].Unit,
                'color': local_product[i].color,
                'AllowAllProducts': local_product[i].AllowAllProducts,
                'CustomerType': local_product[i].CustomerType ,
                'CustomerTypeName': local_product[i].CustomerTypeName, 
                'Description': local_product[i].Description,
                'DescriptionTinymce': local_product[i].DescriptionTinymce,
                'DiscountDuration': local_product[i].DiscountDuration,
                'DiscountOn': local_product[i].DiscountOn, 
                'DiscountTimes': local_product[i].DiscountTimes,
                'IsDiscount': local_product[i].IsDiscount ,
                'ProductMCP': local_product[i].ProductMCP,
                'ShopCode': local_product[i].ShopCode, 
                'ShopName': local_product[i].ShopName,


              })
          }
        }


        this.products.push(selectedProduct)


        localStorage.setItem('products', JSON.stringify(this.products))



      }
    }
  }

  noLimit(selectedProduct: Product) {
    this.selected = !this.selected


    if (localStorage.getItem('products')) {
      let local_product = JSON.parse(localStorage.getItem('products'))
      let index = local_product.findIndex(x => x.ProductID == selectedProduct.ProductID)
      if (index != -1) {
        local_product.splice(index, 1)
        localStorage.setItem('products', JSON.stringify(local_product))
        this.products = []
        return
      }
    }



    if (localStorage.getItem('products')) {
      let local_product = JSON.parse(localStorage.getItem('products'))

      for (let i = 0; i < local_product.length; i++) {
        this.products.push(
          {
            'DiscountAmount': local_product[i].DiscountAmount,
            'DiscountPercent': local_product[i].DiscountPercent,
            'OriginalDiscountPercent':local_product[i].OriginalDiscountPercent,
            'OriginalDiscountAmount':local_product[i].OriginalDiscountAmount,
            'ImageURL': local_product[i].ImageURL,
            'ProductCode': local_product[i].ProductCode,
            'ProductID': local_product[i].ProductID,
            'ProductName': local_product[i].ProductName,
            'ShopID': local_product[i].ShopID,
            'Unit': local_product[i].Unit,
            'color': local_product[i].color,
            'AllowAllProducts': local_product[i].AllowAllProducts,
                'CustomerType': local_product[i].CustomerType ,
                'CustomerTypeName': local_product[i].CustomerTypeName, 
                'Description': local_product[i].Description,
                'DescriptionTinymce': local_product[i].DescriptionTinymce,
                'DiscountDuration': local_product[i].DiscountDuration,
                'DiscountOn': local_product[i].DiscountOn, 
                'DiscountTimes': local_product[i].DiscountTimes,
                'IsDiscount': local_product[i].IsDiscount ,
                'ProductMCP': local_product[i].ProductMCP,
                'ShopCode': local_product[i].ShopCode, 
                'ShopName': local_product[i].ShopName,


          })
      }
    }


    this.products.push(selectedProduct)

    console.log(this.products, 'product list list')

    localStorage.setItem('products', JSON.stringify(this.products))
    

  }

  itemClick(selectedProduct: Product) {
    if (localStorage.getItem('limit') != '0') {
      this.checkItemClick(selectedProduct)
    } else {
      console.log(selectedProduct, 'selectedProduct')
      this.noLimit(selectedProduct)
    }
  }



  // getShop(){
  //   this.shop = JSON.parse(localStorage.getItem('shop_data'))
  // }
  isDescriptionVisible: boolean = false;
  toggleDescription() {
    this.isDescriptionVisible = !this.isDescriptionVisible;
  }
}

import { Component, OnInit } from '@angular/core';
import { ShareService } from 'src/app/share/share.service';

@Component({
  selector: 'app-language-segment',
  templateUrl: './language-segment.page.html',
  styleUrls: ['./language-segment.page.scss'],
})
export class LanguageSegmentPage implements OnInit {

  language: string = ''

  constructor(private share_: ShareService) { }

  ngOnInit() {
    if (localStorage.getItem('language')) {
      if (localStorage.getItem('language') == 'mm') {
        this.language = 'mm'
      } else {
        this.language = 'en'
      }
    } else {
      this.language = 'en'
    }
  }



  tabChange(value: string) {
    this.share_.switchLanguage(value)
    this.language = value
  }

}
